import React from "react";
import styled from "styled-components";
import dancingImg from "./dance-picture.png";

const VisionMissionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
`;

const VisionMissionOverlay = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 30px 0 30px;
  max-width: 500px;
`;

const VisionText = styled.span`
  display: block;
  color: black;
  font-size: 24px;
  font-weight: 800;
`;

const MissionText = styled.span`
  display: block;
  color: black;
  font-size: 24px;
  font-weight: 800;
`;

const TitleContainer = styled.span`
  color: #1b8361;
  font-size: 32px;
  font-style: italic;
`;

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
`;

const DanceImg = styled.img`
  max-width: 100%;
  padding: 20px 0;
`;

const VisionMissionSection = () => {
  return (
    <VisionMissionContainer id="VisionMissionSection">
      <Layout>
        <DanceImg src={dancingImg} />
        <VisionMissionOverlay>
          <MissionText>
            <TitleContainer>Mission</TitleContainer>
            <p>Bringing Christ to where the singles are.</p>
          </MissionText>
          <VisionText>
            <TitleContainer>Vision</TitleContainer>
            <p>
              We are an evangelistic and missionary community committed to
              become families empowered by the Holy Spirit to renew the face of
              the earth.
            </p>
          </VisionText>
        </VisionMissionOverlay>
      </Layout>
    </VisionMissionContainer>
  );
};

export default VisionMissionSection;
