import React from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const jkLuizPng = process.env.PUBLIC_URL + "/images/jk-luiz.png";
const adorationPng = process.env.PUBLIC_URL + "/images/adoration.png";
const fatherLoyPng = process.env.PUBLIC_URL + "/images/fatherLoy.png";
const mfcOntarioSinglesPng =
  process.env.PUBLIC_URL + "/images/mfc-singles-ontario.png";
const ryanPng = process.env.PUBLIC_URL + "/images/the-ryan-factura.png";
const worshipPng = process.env.PUBLIC_URL + "/images/worship.png";

const CarouselStyled = styled(Carousel)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.26);
`;

const PhotoGalleryContainer = styled.div`
  margin-top: 50px;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-top: 25px;
  }
`;

const PhotoGallery = () => {
  return (
    <PhotoGalleryContainer>
      <CarouselStyled
        autoPlay
        showThumbs={false}
        showStatus={false}
        swipeable
        width={"90vw"}
      >
        <div>
          <img src={jkLuizPng} alt="" />
        </div>
        <div>
          <img src={adorationPng} alt="" />
        </div>
        <div>
          <img src={fatherLoyPng} alt="" />
        </div>
        <div>
          <img src={mfcOntarioSinglesPng} alt="" />
        </div>
        <div>
          <img src={ryanPng} alt="" />
        </div>
        <div>
          <img src={worshipPng} alt="" />
        </div>
      </CarouselStyled>
    </PhotoGalleryContainer>
  );
};

export default PhotoGallery;
