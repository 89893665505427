import React from "react";
import HeroSection from "../aboutuspage/HeroSection/HeroSection";
import CoreValuesSection from "../aboutuspage/CoreValuesSection/CoreValuesSection";
import VisionMissionSection from "../aboutuspage/VisionMissionSection/VisionMissionSection";
import PurposeSection from "../aboutuspage/PurposeSection/PurposeSection";
import ContactsFooter from "../shared/ContactsFooter/ContactsFooter";

const AboutUsPage = () => {
  return (
    <>
      <HeroSection />
      <VisionMissionSection />
      <CoreValuesSection />
      <PurposeSection />
      <ContactsFooter />
    </>
  );
};

export default AboutUsPage;
