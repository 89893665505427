import React, { useState } from "react";
import styled from "styled-components";

import eventOne from "./event1.png";
import eventTwo from "./event2.png";
import eventThree from "./event3.png";
import Modal from "../../shared/Modal/Modal";

const events = [
  {
    id: 1,
    name: "MFC Singles Ontario - Christian Life Seminar",
    image: eventOne,
    date: "All Saturdays of May starting May 8, 2021",
    time: "10:30am to 2:30pm EST",
    venue: "Zoom Link to be provided as you register.",
    description: (
      <a
        href="https://forms.gle/WCTjkhPcUQRphRUd6"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here to register
      </a>
    ),
  },
  {
    id: 2,
    name: "MFC Singles Ontario - Covenant Recollection",
    image: eventTwo,
    date: "March 19, 2021 to March 20, 2021",
    time:
      "Day 1: March 19, 2021, 7:30 pm to 10:30 pm. Day 2: March 20, 2021, 10:00 am to 4:00 pm",
    venue: "Zoom Link to be provided by your Couple Coordinators.",
  },
  {
    id: 3,
    name: "MFC Singles Ontario & Calgary - Household Servants Training",
    image: eventThree,
    date: "February 27, 2021",
    time: "11:00 am - 5:00 pm EST",
    venue: "Zoom Link to be provided by your CCs.",
    description: (
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSfbM_mHYkuwA_xEp1aA3v7ytlea5w2O-QL7wQjQY-y_u5upmw/viewform"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here to register
      </a>
    ),
  },
];

const EventsGalleryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
`;

const StyledImageContainer = styled.div`
  margin: 9px;
  max-width: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);

  @media (min-width: 768px) {
    max-width: 30%;
  }
`;

const StyledImages = styled.img`
  height: 100%;
  width: 100%;
  transform: scale(1.03);
  transition: transform 0.5s;
  cursor: pointer;

  &:hover {
    transform: scale(1.13);
    opacity: 1;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const CloseButton = styled.button`
  height: 30px;
  min-width: 70px;
  cursor: pointer;
`;

const EventsGallery = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});

  return (
    <>
      <Modal
        show={showModal}
        header={selectedEvent.name}
        children={
          <Content>
            <div>
              <strong>Date</strong>: {selectedEvent.date}
            </div>
            <div>
              <strong>Time</strong>: {selectedEvent.time}
            </div>
            <div>
              <strong>Venue</strong>: {selectedEvent.venue}
            </div>
            {selectedEvent?.description && (
              <div>
                <strong>Description</strong>: {selectedEvent.description}
              </div>
            )}
          </Content>
        }
        footer={
          <CloseButton
            onClick={() => {
              setShowModal(false);
            }}
          >
            <strong>Close</strong>
          </CloseButton>
        }
      />
      <EventsGalleryContainer>
        {events.map((event) => {
          return (
            <StyledImageContainer key={event.id}>
              <StyledImages
                onClick={() => {
                  setSelectedEvent(event);
                  setShowModal(true);
                }}
                src={event.image}
                key={event.id}
              />
            </StyledImageContainer>
          );
        })}
      </EventsGalleryContainer>
    </>
  );
};

export default EventsGallery;
