import React from "react";
import styled from "styled-components";
import mfcSinglesIntlLogo from "./mfc-singles-international-logo.png";
import facebookImg from "./SocialMediaAssets/facebook.svg";
import instagramImg from "./SocialMediaAssets/instagram.svg";
import twitterImg from "./SocialMediaAssets/twitter.svg";

const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 2px solid black;

  @media (min-width: 768px) {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;

    & > *:not(:last-child) {
      margin-right: 120px;
    }
  }
`;

const StyledMFCLogo = styled.img`
  width: 216px;
  height: 100px;
  margin-right: 12px;
  margin-left: 20px;
`;

const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
`;

const ContactInfoHeader = styled.p`
  margin: 0 0 14px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  color: #1b8361;
`;

const EmailText = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
`;

const SocialMediaHeader = styled.p`
  margin: 0 0 14px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  color: #1b8361;
`;

const SocialMediaIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledSocialMediaIcon = styled.img`
  width: 58px;
  height: 55px;
  margin: 0px 10px;
`;

const ContactsFooter = () => {
  return (
    <ContactsContainer>
      <StyledMFCLogo src={mfcSinglesIntlLogo} />
      <ContactInfoContainer>
        <ContactInfoHeader>Email Us</ContactInfoHeader>
        <EmailText>Email: mfcsingles.ontario@gmail.com</EmailText>
      </ContactInfoContainer>
      <SocialMediaContainer>
        <SocialMediaHeader>Follow Us</SocialMediaHeader>
        <SocialMediaIconsContainer>
          <a
            href="https://www.facebook.com/680584565481751/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledSocialMediaIcon src={facebookImg} />
          </a>
          <a
            href="https://twitter.com/mfcsca?lang=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledSocialMediaIcon src={twitterImg} />
          </a>
          <a
            href="https://www.instagram.com/mfcsontario/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledSocialMediaIcon src={instagramImg} />
          </a>
        </SocialMediaIconsContainer>
      </SocialMediaContainer>
    </ContactsContainer>
  );
};

export default ContactsFooter;
