import React from "react";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Navbar from "./shared/Navigation/MainNavigation";

import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import EventsPage from "./pages/EventsPage";
import ContactUsPage from "./pages/ContactUsPage";
import TitheRegPage from "./pages/TitheRegPage";
//import MainNavigation from './shared/components/Navigation/MainNavigation';

const AppContainer = styled.div``;

const App = () => {
  const routes = (
    <Switch>
      <Route path="/" exact>
        <HomePage />
      </Route>
      <Route path="/about-us" exact>
        <AboutUsPage />
      </Route>
      <Route path="/events" exact>
        <EventsPage />
      </Route>
      <Route path="/contact-us" exact>
        <ContactUsPage />
      </Route>
      <Route path="/tithe-registration" exact>
        <TitheRegPage />
      </Route>
      <Redirect to="/" />
    </Switch>
  );

  return (
    <AppContainer>
      <Router>
        <Navbar />
        <main>{routes}</main>
      </Router>
    </AppContainer>
  );
};

export default App;
