import React from "react";
import styled from "styled-components";
import titoFrank from "./tito-frank.jpg";
import MFCButton from "../../shared/Buttons/MfcButton";

const HeroContainer = styled.section`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${titoFrank});
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
  height: 100%;
`;

const InfoTitle = styled.p`
  color: white;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
`;

const DescriptionOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 180px 0 20px 0;
  padding: 10px;
  max-width: 700px;
  background-color: rgba(0, 0, 0, 0.5);
`;

const DescriptionText = styled.p`
  font-weight: 800;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
`;

const GreenEmphasis = styled.span`
  padding: 10px;
  background-color: white;
  font-style: italic;
  color: #1b8361;
`;

const GreenColor = styled.span`
  color: #1b8361;
`;

const TextContainer = styled.span`
  color: white;
  font-size: 20px;
  display: block;
`;

const ButonContainer = styled.div`
  margin-bottom: 35px;
`;

const HeroSection = () => {
  const handleClick = () => {
    const tempWindow = window.open(
      "https://cdn.fbsbx.com/v/t59.2708-21/129135476_163818325453093_2951755506621168995_n.pdf/MFC-Pre-Authorized-Debit-Form-PAD-2-1-2.pdf?_nc_cat=105&ccb=1-3&_nc_sid=0cab14&_nc_ohc=Tb7Wqe6HmRoAX8oArmL&_nc_ht=cdn.fbsbx.com&oh=4d9dec316057d600f19aafadc6ab846f&oe=604E162F&dl=1"
    );
    setInterval(function () {
      tempWindow.close();
    }, 500);
  };

  return (
    <HeroContainer id="heroSection">
      <DescriptionOverlay>
        <InfoTitle>
          <GreenEmphasis>MFC Singles GIG</GreenEmphasis>
        </InfoTitle>
        <DescriptionText>
          <TextContainer>
            <GreenColor>MFC Singles GIG </GreenColor>(
            <GreenColor>God Is Generous</GreenColor>) is the tithing system of
            the Singles section of Missionary Families of Christ.
            <br />
            <br />
            It seeks to inspire the singles to cheerfully practice the habit of
            financial stewardship through spiritual giving.
          </TextContainer>
        </DescriptionText>
      </DescriptionOverlay>
      <ButonContainer>
        <MFCButton text="DOWNLOAD FORM" handleClick={handleClick} />
      </ButonContainer>
    </HeroContainer>
  );
};

export default HeroSection;
