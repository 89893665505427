import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import MFCButton from "../../shared/Buttons/MfcButton";

const ContactSectionContainer = styled.section`
  padding: 0 20px;
`;

const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContactUsTitle = styled.p`
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  color: #1b8361;
`;

const ContactUsDescription = styled.p`
  font-size: 20px;
  text-align: center;
  max-width: 1000px;
`;

const ButtonContainer = styled.div`
  margin-bottom: 32px;
`;

const ContactsSection = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/contact-us");
    window.scrollTo(0, 0);
  };

  return (
    <ContactSectionContainer id="ContactsSection">
      <ContactUsContainer>
        <ContactUsTitle>Contact Us</ContactUsTitle>
        <ContactUsDescription>
          No matter what you think of when you hear the word "Church", we
          believe that Christ is interested in your life more than you think.
          That's why we want to invite you to our next Christian Life Seminar.
          Don't worry: we all start somewhere.
        </ContactUsDescription>
        <ButtonContainer>
          <MFCButton text="CONTACT US" handleClick={handleClick} />
        </ButtonContainer>
      </ContactUsContainer>
    </ContactSectionContainer>
  );
};

export default ContactsSection;
