import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import MFCButton from "../../shared/Buttons/MfcButton";
import blessedSacramentPng from "./blessed-sacrament.png";

const InfoOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InfoTitle = styled.p`
  font-weight: bold;
  font-size: 32px;
  text-align: center;
`;

const InfoText = styled.p`
  margin: 0;
  max-width: 1000px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const InfoContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  background-position: top center;
  background-repeat: no-repeat;
  & > * {
    margin-bottom: 30px;
  }
`;

const GreenEmphasis = styled.span`
  color: #1b8361;
`;

const BlessedSacramentImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${blessedSacramentPng});
  background-attachment: fixed;
`;

const BibleVerse = styled.p`
  max-width: 630px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 54px;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 72px;
  }
`;

const SmallerText = styled.span`
  font-size: 32px;

  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

const InfoSection = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/about-us");
    window.scrollTo(0, 0);
  };

  return (
    <section id="infoSection">
      <InfoOverlay>
        <InfoContainer>
          <InfoTitle>
            What is <GreenEmphasis>MFC Singles?</GreenEmphasis>
          </InfoTitle>
          <InfoText>
            <GreenEmphasis>
              Missionary Families of Christ - Singles Ministry -
            </GreenEmphasis>{" "}
            is a Catholic young adults ministry which caters to single men and
            women between 21 to 39 years old. Accompanying singles in
            discovering their <GreenEmphasis>life direction</GreenEmphasis> and
            responding to their <GreenEmphasis>vocation</GreenEmphasis>.
            Bringing the <GreenEmphasis>Cause of Christ</GreenEmphasis> to where
            the singles are.
          </InfoText>
          <MFCButton text="ABOUT US" handleClick={handleClick} />
        </InfoContainer>
        <BlessedSacramentImageContainer>
          <BibleVerse>
            <SmallerText>
              WHATEVER YOU DO, DO IT
              <br />
              ALL FOR THE
              <br />
            </SmallerText>{" "}
            GLORY OF GOD{" "}
            <SmallerText>
              <br />1 CORINTHIANS 10:31
            </SmallerText>
          </BibleVerse>
        </BlessedSacramentImageContainer>
      </InfoOverlay>
    </section>
  );
};

export default InfoSection;
