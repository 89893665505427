import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as DismissSVG } from "./times-circle-solid.svg";

const StyledAside = styled.aside`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 300px;
  width: 100%;
  background: black;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

  @media (min-width: 768px) {
    display: none;
  }
`;

const SideBarHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const StyledDismiss = styled(DismissSVG)`
  width: 40px;
  height: 40px;
  color: white;
`;

const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  background: black;
  border: 0;
  margin-right: 70px;
  margin-top: 35px;
`;

const SideDrawer = (props) => {
  const content = (
    <CSSTransition
      in={props.show}
      timeout={200}
      classNames="slide-in-top"
      mountOnEnter
      unmountOnExit
    >
      <StyledAside onClick={props.onClick}>
        <SideBarHeader>
          <CloseButton>
            <StyledDismiss />
          </CloseButton>
        </SideBarHeader>
        {props.children}
      </StyledAside>
    </CSSTransition>
  );

  return ReactDOM.createPortal(content, document.getElementById("drawer-hook"));
};

export default SideDrawer;
