import React from "react";
import styled from "styled-components";
import worshipImg from "./worship-concert.jpg";

const HeroContainer = styled.section`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${worshipImg});
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
`;

const InfoTitle = styled.p`
  color: white;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
`;

const DescriptionOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 180px 0 20px 0;
  padding: 10px;
  max-width: 700px;
  background-color: rgba(0, 0, 0, 0.5);
`;

const DescriptionText = styled.p`
  font-weight: 800;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
`;

const GreenEmphasis = styled.span`
  padding: 10px;
  background-color: white;
  font-style: italic;
  color: #1b8361;
`;

const GreenColor = styled.span`
  color: #1b8361;
`;

const TextContainer = styled.span`
  color: white;
  font-size: 20px;
  display: block;
`;

const HeroSection = () => {
  return (
    <HeroContainer id="heroSection">
      <DescriptionOverlay>
        <InfoTitle>
          What is <GreenEmphasis>MFC Singles?</GreenEmphasis>
        </InfoTitle>
        <DescriptionText>
          <TextContainer>
            <GreenColor>MFC Singles</GreenColor> is a Catholic Ministry where
            single men and women would experience Jesus Christ, know their state
            of life, be effective and efficient in their workplace, a strong
            witness of God’s love and faithfulness in their family, peers, and
            community, where everyone would experience true brotherhood and
            sisterhood having God at the center of it.
          </TextContainer>
        </DescriptionText>
      </DescriptionOverlay>
    </HeroContainer>
  );
};

export default HeroSection;
