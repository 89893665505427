import React from "react";
import ContactsFooter from "../shared/ContactsFooter/ContactsFooter";
import HeroSection from "../titheregpage/HeroSection/HeroSection";

const TitheRegPage = () => {
  return (
    <>
      <HeroSection />
      <ContactsFooter />
    </>
  );
};

export default TitheRegPage;
