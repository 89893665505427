import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import MFCOntarioLogo from "./MFC-Singles-Logo-Ontario.png";
import SideDrawer from "./SideDrawer";
import BackDrop from "./Backdrop";

const Banner = styled.nav.attrs({
  role: "banner",
})`
  position: absolute;
  padding: 35px 0px 0px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  /* width: 100%; */
  width: initial;

  @media (max-width: 900px) {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
`;

const StyledMFCLogo = styled.img`
  max-width: 150px;
  max-height: 107px;
  cursor: pointer;
`;

const Menu = styled.ul.attrs({
  role: "menu",
})`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    padding-right: 32px;

    /* & means the current element */
    & > *:not(:last-child) {
      margin-right: 43px;
    }
  }
`;

const SideMenu = styled.ul.attrs({
  role: "menu",
})`
  padding: 0;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;

  a {
    color: #ffffff;
    border: 1px solid transparent;
    text-decoration: none;
    font-weight: 700;
  }

  a:hover,
  a:active,
  a.active {
    color: #ffffff;
    border-bottom: 2px solid #ffffff;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    display: flex;
    color: white;
  }
`;

const MenuHamburger = styled.button`
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  padding-right: 40px;

  span {
    display: block;
    width: 3rem;
    height: 2.5px;
    background: white;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const NavLinks = () => {
  return (
    <>
      <ListItem>
        <NavLink to="/" exact>
          HOME
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink to="/about-us" exact>
          ABOUT US
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink to="/events" exact>
          EVENTS
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink to="/tithe-registration" exact>
          WAYS TO HELP
        </NavLink>
      </ListItem>
      <ListItem>
        <NavLink to="/contact-us" exact>
          CONTACT US
        </NavLink>
      </ListItem>
    </>
  );
};

const MainNavigation = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const history = useHistory();

  const handleLogoClick = () => {
    history.push("/");
    window.scrollTo(0, 0);
  };

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  return (
    <>
      {drawerIsOpen && <BackDrop onClick={closeDrawerHandler} />}
      <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <SideMenu>
          <NavLinks />
        </SideMenu>
      </SideDrawer>
      <Banner>
        <StyledMFCLogo src={MFCOntarioLogo} onClick={handleLogoClick} />
        <MenuHamburger onClick={openDrawerHandler}>
          <span />
          <span />
          <span />
        </MenuHamburger>
        <Menu>
          <NavLinks />
        </Menu>
      </Banner>
    </>
  );
};

export default MainNavigation;
