import React from "react";
import HeroSection from "../homepage/HeroSection/HeroSection";
import InfoSection from "../homepage/InfoSection/InfoSection";
import EventsSection from "../homepage/EventsSection/EventsSection";
import ContactSection from "../homepage/ContactSection/ContactSection";
import PhotoGallery from "../homepage/PhotoGallery/PhotoGallery";
import ContactsFooter from "../shared/ContactsFooter/ContactsFooter";

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <InfoSection />
      <EventsSection />
      <PhotoGallery />
      <ContactSection />
      <ContactsFooter />
    </>
  );
};

export default HomePage;
