import React from "react";
import styled from "styled-components";
import worshipCongressImg from "./worship-congress.png";

const CoreValuesContainer = styled.section`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${worshipCongressImg});
  background-color: rgba(0, 0, 0, 0.5);
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
`;

const InfoTitle = styled.p`
  color: white;
  font-weight: bold;
  font-size: 32px;
`;

const DescriptionOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 20px 0;
  padding: 10px;
  max-width: 700px;
  background-color: rgba(0, 0, 0, 0.5);
`;

const DescriptionText = styled.div`
  margin-top: 0px;

  font-weight: 800;
`;

const GreenEmphasis = styled.span`
  padding: 10px;
  background-color: white;
  font-style: italic;
  color: #1b8361;
`;

const TextContainer = styled.span`
  color: white;
  font-size: 20px;
  display: block;
`;

const StyledList = styled.ul`
  * {
    margin-bottom: 10px;
  }
`;

const CoreValuesSection = () => {
  return (
    <CoreValuesContainer id="CoreValuesSection">
      <DescriptionOverlay>
        <InfoTitle>
          Our <GreenEmphasis>Core Values</GreenEmphasis>
        </InfoTitle>
        <DescriptionText>
          <TextContainer>
            <StyledList>
              <li>CENTERED ON CHRIST</li>
              <li>EVANGELISTIC AND MISSIONARY</li>
              <li>FOCUSED ON FAMILY</li>
              <li>BEING A COMMUNITY</li>
              <li>LIVING A PREFERENTIAL OPTION FOR THE POOR</li>
              <li>EXERCISING SERVANT LEADERSHIP</li>
              <li>BEING A SERVANT TO THE CHURCH</li>
            </StyledList>
          </TextContainer>
        </DescriptionText>
      </DescriptionOverlay>
    </CoreValuesContainer>
  );
};

export default CoreValuesSection;
