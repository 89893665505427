import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import MFCButton from "../../shared/Buttons/MfcButton";

const EventsOverlay = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EventsHeader = styled.p`
  font-weight: bold;
  font-size: 32px;
  text-align: center;
`;

const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  & > * {
    margin-bottom: 30px;
  }
`;

const GreenEmphasis = styled.span`
  color: #1b8361;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTextContainer = styled.div`
  max-width: 700px;
  text-align: center;
  font-size: 20px;

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const EventsSection = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/events");
    window.scrollTo(0, 0);
  };

  return (
    <section id="eventsSection">
      <EventsOverlay>
        <EventsContainer>
          <EventsHeader>
            <GreenEmphasis>Upcoming Events</GreenEmphasis>
          </EventsHeader>
          <StyledTextContainer>
            Join us on any of our events, and feel free to bring a friend!
            Questions? Email us at mfcsingles.ontario@gmail.com
          </StyledTextContainer>
          <ButtonContainer>
            <MFCButton text="VIEW EVENTS" handleClick={handleClick} />
          </ButtonContainer>
        </EventsContainer>
      </EventsOverlay>
    </section>
  );
};

export default EventsSection;
