import React from "react";
import styled from "styled-components";
import { ReactComponent as ButtonLogo } from "./mfc-singles-logo-flames.svg";

const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  background: #3f3f3f;
  border: 5px solid #ffffff;
  box-sizing: border-box;
  border-radius: 100px;

  & > *:not(:last-child) {
    margin-right: 16px;
  }

  &:hover {
    border: 5px solid #1b8361;
    cursor: pointer;
  }
`;

const ButtonText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-right: 28px;

  color: #ffffff;
`;

const MfcButton = ({ text, handleClick }) => {
  return (
    <ButtonContainer type="button" onClick={handleClick}>
      <ButtonLogo />
      <ButtonText>{text}</ButtonText>
    </ButtonContainer>
  );
};

export default MfcButton;
