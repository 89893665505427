import React from "react";
import styled from "styled-components";
import prayerImg from "./congress-prayer.png";

const PurposeContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
  padding-left: 20px;
  padding-right: 20px;
`;

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
`;

const PrayerImg = styled.img`
  max-width: 100%;
  margin: 20px 0;
`;

const InfoTitle = styled.p`
  font-weight: bold;
  font-size: 32px;
`;

const DescriptionOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 20px 0;
  max-width: 700px;
`;

const DescriptionText = styled.div`
  margin-top: 0px;
  font-weight: 800;
`;

const GreenEmphasis = styled.span`
  padding: 5px;
  font-style: italic;
  color: #1b8361;
`;

const TextContainer = styled.span`
  font-size: 20px;
  display: block;
`;

const StyledList = styled.ul`
  * {
    margin-bottom: 10px;
  }
`;

const PurposeSection = () => {
  return (
    <PurposeContainer id="PurposeSection">
      <Layout>
        <PrayerImg src={prayerImg} />
        <DescriptionOverlay>
          <InfoTitle>
            <GreenEmphasis>Our Purpose</GreenEmphasis>
          </InfoTitle>
          <DescriptionText>
            <TextContainer>
              <StyledList>
                <li key={1}>
                  <GreenEmphasis>Honor</GreenEmphasis> God through Holiness of
                  Life
                </li>
                <li key={2}>
                  <GreenEmphasis>Empower</GreenEmphasis> Men and Women to
                  Witness God’s Love and Faithfulness
                </li>
                <li key={3}>
                  <GreenEmphasis>Act</GreenEmphasis> out God-given Skills and
                  Talents in the service of others
                </li>
                <li key={4}>
                  <GreenEmphasis>Relate</GreenEmphasis> with Excellence in all
                  Areas of Life through Meaningful Fellowship
                </li>
                <li key={5}>
                  <GreenEmphasis>Testify</GreenEmphasis> God’s Love by allowing
                  them to be used as His instruments
                </li>
              </StyledList>
            </TextContainer>
          </DescriptionText>
        </DescriptionOverlay>
      </Layout>
    </PurposeContainer>
  );
};

export default PurposeSection;
