import React from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: flex;
  justify-content: space-between;
  padding: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const FormBody = styled.form`
  padding: 0;
  @media (min-width: 768px) {
    width: 50%;
    padding: 75px 0px 75px 75px;
  }
`;

const InputColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const TextInput = styled.input`
  width: 100%;
  padding: 15px;
  border: none;
  border-bottom: 1px solid lightslategray;

  @media (min-width: 768px) {
    margin-left: 10px;
  }
`;

const TextAreaInput = styled.textarea`
  width: 100%;
  margin-left: 0;
  padding: 20px;
  resize: none;
  border: none;
  border-bottom: 1px solid lightslategray;

  @media (min-width: 768px) {
    margin-left: 10px;
    padding: 15px;
  }
`;

const MessageButton = styled.button`
  float: right;
  padding: 10px;
  margin-top: 10px;
  background-color: #3b8963;
  color: white;
  border: none;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    background-color: #356e52;
  }
`;

const ContactFormSection = () => {
  function sendEmail(e) {
    e.preventDefault();

    //sendForm params depend on emailjs account
    emailjs
      .sendForm(
        "gmail",
        "singles_template",
        e.target,
        "user_O4WSV7LzfaO2D0cqPKvCV"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <SectionContainer>
      <FormBody onSubmit={sendEmail}>
        <InputColumns>
          <TextInput type="text" placeholder="Name" name="name" />
          <TextInput type="text" placeholder="Email" name="email" />
        </InputColumns>
        <InputColumns>
          <TextInput type="text" placeholder="Area" name="area" />
          <TextInput type="text" placeholder="Service" name="service" />
        </InputColumns>
        <InputColumns>
          <TextAreaInput placeholder="Message" name="message" />
        </InputColumns>
        <MessageButton>Send Message</MessageButton>
      </FormBody>
    </SectionContainer>
  );
};

export default ContactFormSection;
