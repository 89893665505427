import React from "react";
import styled from "styled-components";
import heroImg from "./hero-image.png";
import MFCButton from "../../shared/Buttons/MfcButton";

const HeroContainer = styled.section`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${heroImg});
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-attachment: fixed;
`;

const HeroOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeroText = styled.p`
  margin: 0 0 64px 0;
  color: white;
  font-weight: 800;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const LargeText = styled.span`
  font-size: 32px;
  display: block;

  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

const XLargeText = styled.span`
  font-size: 54px;
  display: block;

  @media (min-width: 768px) {
    font-size: 72px;
  }
`;

const XXLargeText = styled.span`
  font-size: 72px;
  display: block;

  @media (min-width: 768px) {
    font-size: 96px;
  }
`;

const HeroSection = () => {
  const handleClick = () => {
    document.getElementById("infoSection").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <HeroContainer id="heroSection">
      <HeroOverlay>
        <HeroText>
          <LargeText>BRINGING THE </LargeText>
          <XLargeText>CAUSE OF </XLargeText>
          <XXLargeText>CHRIST </XXLargeText>
          <LargeText>TO WHERE </LargeText>
          <XLargeText>THE SINGLES ARE</XLargeText>
        </HeroText>
        <MFCButton text="LEARN MORE" handleClick={handleClick} />
      </HeroOverlay>
    </HeroContainer>
  );
};

export default HeroSection;
