import React from "react";
import styled from "styled-components";
import HeroSection from "../eventspage/HeroSectionComponent/HeroSection";
import EventsGallery from "../eventspage/EventsGallery/EventsGallery";
import ContactsFooter from "../shared/ContactsFooter/ContactsFooter";

const EventsPageContainer = styled.div`
  background-color: #fafafa;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const EventsPage = () => {
  return (
    <EventsPageContainer>
      <HeroSection />
      <EventsGallery />
      <ContactsFooter />
    </EventsPageContainer>
  );
};

export default EventsPage;
