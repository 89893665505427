import React from "react";
import styled from "styled-components";
import praisefestImg from "./praisefest.png";

const HeroContainer = styled.section`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${praisefestImg});
  background-color: rgba(0, 0, 0, 0.5);
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
`;

const InfoTitle = styled.p`
  color: white;
  font-weight: bold;
  font-size: 65px;
  text-align: center;
  font-style: italic;
`;

const HeroSection = () => {
  return (
    <HeroContainer id="heroSection">
      <InfoTitle>Upcoming Events</InfoTitle>
    </HeroContainer>
  );
};

export default HeroSection;
