import React from "react";
import styled from "styled-components";
import ContactHeroImage from "./ContactHero.png";

const HeroSection = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${ContactHeroImage});
  background-color: rgba(0, 0, 0, 0.5);
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
`;

const HeroText = styled.h1`
  color: white;
  font-weight: bold;
  font-size: 60px;
  text-align: center;
  font-style: italic;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ContactHeroSection = () => {
  return (
    <>
      <HeroSection>
        <HeroText>Contact Us</HeroText>
      </HeroSection>
    </>
  );
};

export default ContactHeroSection;
