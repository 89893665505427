import React from "react"
import ContactHeroSection from "../contactuspage/ContactHeroSection/ContactHeroSection"
import ContactFormSection from "../contactuspage/ContactFormSection/ContactFormSection"
import ContactsFooter from "../shared/ContactsFooter/ContactsFooter"

const ContactUsPage = () => {
    return (
        <>
            <ContactHeroSection />
            <ContactFormSection />
            <ContactsFooter />
        </>
    )
}

export default ContactUsPage; 